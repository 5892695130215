var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-offline-container"},[_c('p',[_vm._v(_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.INFO_TEXT')))]),_c('div',{staticClass:"package-action-button-container"},[_c('div',{staticClass:"package-action-button cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.downloadDocuments($event)}}},[_vm._m(0),_c('div',{staticClass:"package-action-button__title"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.ACTION_DOWNLOAD_TITLE'))+" ")]),_c('div',{staticClass:"package-action-button__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.ACTION_DOWNLOAD_SUBTITLE'))+" ")])]),_c('div',{staticClass:"package-action-button cursor-pointer",class:['upload-section', { disabled: !_vm.isDownloaded }],on:{"click":function($event){$event.stopPropagation();return _vm.uploadDocuments($event)}}},[_vm._m(1),_c('div',{staticClass:"package-action-button__title"},[_vm._v(_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.ACTION_UPLOAD_TITLE'))+" ")]),_c('div',{staticClass:"package-action-button__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.ACTION_UPLOAD_SUBTITLE'))+" ")])]),_c('form',{attrs:{"novalidate":"novalidate","id":"st_upload_offline_file_form"}},[_c('div',{staticClass:"form-group"},[_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"upload_documents",attrs:{"placeholder":"","accept":".pdf","name":"upload_documents"},on:{"change":_vm.sendDocuments}})],1)])]),_c('div',{staticClass:"timeline-container"},[_c('ul',{staticClass:"timeline"},[_c('li',{class:[
                    'timeline__step',
                    {
                    active: !_vm.isDownloaded,
                    checked: _vm.isDownloaded,
                    fix: _vm.isDownloaded && _vm.isSigned,
                    } ]},[_vm._m(2),_c('div',{staticClass:"timeline__step-marker"},[(!_vm.isDownloaded)?_c('span',[_vm._v("1")]):_c('i',{staticClass:"fas fa-check text-white"})]),_c('div',{staticClass:"timeline__step-title"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.STEP_DOWNLOAD'))+" ")])]),_c('li',{class:[
                    'timeline__step',
                    {
                    active: _vm.isDownloaded && !_vm.isSigned,
                    checked: _vm.isSigned,
                    fix: _vm.isSigned && _vm.isVerified,
                    } ]},[_vm._m(3),_c('div',{staticClass:"timeline__step-marker"},[(!_vm.isSigned)?_c('span',[_vm._v("2")]):_c('i',{staticClass:"fas fa-check text-white"})]),_c('div',{staticClass:"timeline__step-title"},[_vm._v(_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.STEP_SIGN')))])]),_c('li',{class:[
                    'timeline__step',
                    {
                    active: _vm.isSigned && !_vm.isVerified,
                    checked: _vm.isVerified,
                    fix: _vm.isVerified && _vm.isChecked,
                    } ]},[_vm._m(4),_c('div',{staticClass:"timeline__step-marker"},[(!_vm.isVerified)?_c('span',[_vm._v("3")]):_c('i',{staticClass:"fas fa-check text-white"})]),_c('div',{staticClass:"timeline__step-title"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.STEP_VERIFY'))+" ")])]),_c('li',{class:[
                    'timeline__step',
                    {
                    active: _vm.isVerified && !_vm.isChecked,
                    checked: _vm.isChecked,
                    fix: _vm.isChecked,
                    } ]},[_vm._m(5),_c('div',{staticClass:"timeline__step-marker"},[(!_vm.isChecked)?_c('span',[_vm._v("4")]):_c('i',{staticClass:"fas fa-check text-white"})]),_c('div',{staticClass:"timeline__step-title"},[_vm._v(" "+_vm._s(_vm.$t('APPLICATION.SIGNATURE.OFFLINE.STEP_CHECKED'))+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"package-action-button__icon"},[_c('i',{staticClass:"fas fa-cloud-download-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"package-action-button__icon"},[_c('i',{staticClass:"fas fa-cloud-upload-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline__step-icon"},[_c('i',{staticClass:"fas fa-file-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline__step-icon"},[_c('i',{staticClass:"fas fa-pencil-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline__step-icon"},[_c('i',{staticClass:"fas fa-search-plus"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline__step-icon"},[_c('i',{staticClass:"fas fa-clipboard-check"})])}]

export { render, staticRenderFns }